import { Container, Navbar, Button} from 'react-bootstrap';

function OffcanvasNav(props) {


  return (
      <Navbar fixed="top" id="Navback" className="bg-transparent" height="180px"> 
      <Container className="justify-content-around" >
      <Navbar.Brand className="py-3" href="/">
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 62.6 33.08" height="35">
<g id="textdraw">
<path pathLength="1" d="M59.35,12c-3.29-1.12-13.14-4.57-13.1,3.4,0,6,10.71,3.46,13.86,1h0c-.12-2.3.32-5.78.44-7.81A5.61,5.61,0,0,0,57,2.76a10.5,10.5,0,0,0-7.69,0l-.2,0C39.71,5.47,40.1,19,38.29,19.11S32.76,2.24,31.74,2.2,27.1,19.07,25,19.11C22.46,19.16,20.16.87,19.23,2.2,16.1,6.68,1.26,21.62,2,28.11c.52,4.42,5.73,3.13,7.13.88,1.8-2.88.27-20.41.08-26.79"/>
</g>
<path d="M17.79,32.78V26c0-.2.12-.3.35-.3h3.17a3.62,3.62,0,0,1,2.38.65,2.61,2.61,0,0,1,.75,2.07v1.91a2.62,2.62,0,0,1-.74,2.07,3.63,3.63,0,0,1-2.39.65H18.14c-.23,0-.35-.1-.35-.3m3.52-.29a4.06,4.06,0,0,0,1.44-.21,1.42,1.42,0,0,0,.77-.67,2.87,2.87,0,0,0,.23-1.25V28.45a2.87,2.87,0,0,0-.23-1.25,1.42,1.42,0,0,0-.77-.67,4.08,4.08,0,0,0-1.44-.2H18.48v6.16Z"/>
<path d="M32.33,32.78c0,.2-.11.3-.34.3H27.06c-.23,0-.35-.1-.35-.3V26c0-.2.12-.3.35-.3H32c.23,0,.34.1.34.3s-.11.3-.34.3H27.4v2.73h3c.23,0,.34.1.34.3s-.11.3-.34.3h-3v2.83H32c.23,0,.34.09.34.29"/>
<path d="M34.52,32.62a1.83,1.83,0,0,1-.61-1.43A.24.24,0,0,1,34,31a.4.4,0,0,1,.26-.08.33.33,0,0,1,.34.29,1.27,1.27,0,0,0,.39,1,2.15,2.15,0,0,0,1.27.28h1.89a2,2,0,0,0,1.31-.31A1.43,1.43,0,0,0,39.82,31a1.42,1.42,0,0,0-.37-1.13,2,2,0,0,0-1.3-.31h-1.8a2.58,2.58,0,0,1-1.7-.47,1.84,1.84,0,0,1-.54-1.47,1.83,1.83,0,0,1,.54-1.46,2.53,2.53,0,0,1,1.68-.47h1.86a2.57,2.57,0,0,1,1.64.44,1.75,1.75,0,0,1,.57,1.34.23.23,0,0,1-.09.22.39.39,0,0,1-.25.08.36.36,0,0,1-.24-.08.27.27,0,0,1-.1-.21,1.12,1.12,0,0,0-.37-.93,2,2,0,0,0-1.16-.26H36.33a1.86,1.86,0,0,0-1.19.29,1.74,1.74,0,0,0,0,2.09,1.84,1.84,0,0,0,1.21.3h1.8a2.68,2.68,0,0,1,1.79.49A1.91,1.91,0,0,1,40.51,31a1.93,1.93,0,0,1-.57,1.54,2.73,2.73,0,0,1-1.79.49H36.26a2.75,2.75,0,0,1-1.74-.45"/>
<path d="M42.87,33a.28.28,0,0,1-.09-.22V26a.29.29,0,0,1,.09-.22.39.39,0,0,1,.25-.08.37.37,0,0,1,.25.08.3.3,0,0,1,.1.22v6.75a.29.29,0,0,1-.1.22.37.37,0,0,1-.25.08.39.39,0,0,1-.25-.08"/>
<path d="M46.4,32.51a2.26,2.26,0,0,1-.66-1.8v-2.6a2.28,2.28,0,0,1,.65-1.81,3.13,3.13,0,0,1,2.08-.57h1.59a3,3,0,0,1,1.8.44,1.78,1.78,0,0,1,.66,1.41c0,.2-.1.3-.34.3a.31.31,0,0,1-.35-.3,1.21,1.21,0,0,0-.44-1,2.37,2.37,0,0,0-1.33-.28H48.47a3.34,3.34,0,0,0-1.21.17,1.2,1.2,0,0,0-.64.55,2.39,2.39,0,0,0-.19,1.06v2.6a2.32,2.32,0,0,0,.19,1.05,1.16,1.16,0,0,0,.64.55,3.34,3.34,0,0,0,1.22.18h1.58a3.08,3.08,0,0,0,1.07-.15.91.91,0,0,0,.55-.48,2.11,2.11,0,0,0,.17-.93v-.71l-1.45,0c-.23,0-.34-.09-.34-.29s.11-.3.34-.3h1.79c.23,0,.34.1.34.3v1A2.06,2.06,0,0,1,52,32.57a2.85,2.85,0,0,1-1.89.51H48.48a3.13,3.13,0,0,1-2.08-.57"/>
<path d="M61.48,26v6.75c0,.2-.11.3-.34.3a.47.47,0,0,1-.34-.17l-5.44-6.06v5.93c0,.2-.11.3-.34.3s-.34-.1-.34-.3V26c0-.2.11-.3.34-.3a.47.47,0,0,1,.35.17L60.8,32V26c0-.2.11-.3.34-.3s.34.1.34.3"/>
<circle cx="14.68" cy="31.83" r="1.15" stroke="#000" stroke-miterlimit="10" stroke-width="0.19"/>
<line x1="61.78" y1="23.59" x2="17.9" y2="23.59" fill="none" stroke="#000" stroke-miterlimit="10" stroke-width="0.91"/>
</svg>
  </Navbar.Brand>
              <Button href="#contact" variant="light bg-transparent border-0 text-uppercase">
  contact
</Button>
</Container>

        </Navbar>
  );
}

export default OffcanvasNav;
