import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './App.scss'; // Assuming you have a separate CSS file for your app styles

import Nav from "./components/NavBar";
import Accueil from "./components/Accueil";
import Footer from "./components/Footer";
import SvgWavesAnim from "./components/SvgWavesAnim"
import StackCards from "./components/StackCard"
import FauneCards from "./components/Faunecards"

function App() {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration
      once: true, // Whether animation should only happen once
    });
  }, []);

  return (
    <div className="App">
          <Nav />
        <Accueil />
        <div className="parallax"></div>
<StackCards />
<SvgWavesAnim />
<div className="parallax1"></div>
<FauneCards />
        <Footer />
    </div>
  );
}

export default App;

