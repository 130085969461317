import { Container, Row, Col } from "react-bootstrap";


function Footer() {
  return (
    <>
      <footer id="contact">
      <svg className="mt-n2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1366 113.08" width="100%"><path d="M1366,17.08s-65,10-146,7c-65.43-2.43-68.37-24.09-164-21-124,4-125,14-227,16C723,21.16,706.84-1.48,621,.08c-55,1-136,21-193,20-32.47-.57-156-18-267-17-83,.75-161,16-161,16v94H1366Z" fill="#212529"/></svg>
       <Container fluid className="mt-n2 bg-primary">
        <div className="text-center mx-auto pt-1">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 175.73 119.17" height="120">
    <line x1="99.68" y1="80.22" x2="79.33" y2="80.22" fill="none" stroke="#eaeaea" stroke-miterlimit="10" stroke-width="0.91"/>
    <line x1="112.24" y1="80.22" x2="104.07" y2="80.22" fill="none" stroke="#eaeaea" stroke-miterlimit="10" stroke-width="0.91"/>
    <line x1="0.87" y1="79.14" x2="18.07" y2="79.14" fill="none" stroke="#eaeaea" stroke-miterlimit="10" stroke-width="1"/>
    <line x1="95.73" y1="83.81" x2="67.5" y2="83.81" fill="none" stroke="#eaeaea" stroke-miterlimit="10" stroke-width="0.91"/>
    <line x1="81.43" y1="88.36" x2="63.06" y2="88.36" fill="none" stroke="#eaeaea" stroke-miterlimit="10" stroke-width="0.91"/>
    <line x1="77.04" y1="96.75" x2="65.34" y2="96.75" fill="none" stroke="#eaeaea" stroke-miterlimit="10" stroke-width="1"/>
    <line x1="73.33" y1="102.11" x2="59.66" y2="102.11" fill="none" stroke="#eaeaea" stroke-miterlimit="10" stroke-width="0.91"/>
    <line x1="22.05" y1="97.06" x2="45.32" y2="97.06" fill="none" stroke="#eaeaea" stroke-miterlimit="10" stroke-width="1"/>
    <line x1="28.26" y1="92" x2="43.22" y2="92" fill="none" stroke="#eaeaea" stroke-miterlimit="10" stroke-width="1"/>
    <line x1="12.39" y1="83.45" x2="38.96" y2="83.45" fill="none" stroke="#eaeaea" stroke-miterlimit="10" stroke-width="1"/>
    <line x1="12.39" y1="87.19" x2="28.74" y2="87.19" fill="none" stroke="#eaeaea" stroke-miterlimit="10" stroke-width="1"/>
    <path d="M.45,76.27a49.61,49.61,0,0,1,99.21,0" fill="none" stroke="#eaeaea" stroke-miterlimit="10" stroke-width="0.91"/>
    <path d="M33.87,0Q42.6,0,48,4.07a23.84,23.84,0,0,1,7.93,11A45.8,45.8,0,0,1,58.5,30.76a50.56,50.56,0,0,1-.66,10c-.55,3.49-1,6.61-1.3,9.38s-.24,4.77.25,6c.48,1,1.55,1.66,3.22,1.84s3.13.32,4.37.4v1.43a26.31,26.31,0,0,0-4.15.74,4,4,0,0,0-3,2.45A14.17,14.17,0,0,0,57,69c.3,2.77.7,5.86,1.2,9.29a59.27,59.27,0,0,1,.62,9.87,46,46,0,0,1-2.41,15.61,24,24,0,0,1-7.81,11.2q-5.34,4.19-14.07,4.23L34.24,118a19.66,19.66,0,0,0,8-3.91,17.38,17.38,0,0,0,4.61-6.39A30.5,30.5,0,0,0,49,99a92.91,92.91,0,0,0,.52-10.81q0-4.58-.27-9.22a67.36,67.36,0,0,1,.09-8.8,16.94,16.94,0,0,1,1.9-6.95,7.58,7.58,0,0,1,5.12-3.68,8.65,8.65,0,0,1-5.17-3.9,16.58,16.58,0,0,1-2-6.72,63.58,63.58,0,0,1-.2-8.65c.13-3.15.18-6.3.17-9.44A75.76,75.76,0,0,0,48,16.87,22.42,22.42,0,0,0,43.56,6.81q-3.24-4-10-5.66Z" fill="#eaeaea"/>
    <path d="M29.2,52.73l0-3.35,19,8.72v2.79L29.29,69.82l0-3.35,17-8.17v2.36Z" fill="#eaeaea"/>
    <path d="M172.48,69c-3.28-1.12-13.14-4.57-13.1,3.41,0,6,10.72,3.45,13.87,1l0,0c-.12-2.3.31-5.77.43-7.8a5.6,5.6,0,0,0-3.54-5.82,10.53,10.53,0,0,0-7.7,0l-.19.05C152.84,62.39,153.23,76,151.43,76c-2,.08-5.54-16.86-6.55-16.91S140.24,76,138.09,76c-2.49,0-4.8-18.23-5.72-16.91-3.14,4.48-18,19.42-17.2,25.91.53,4.42,5.73,3.13,7.14.88,1.8-2.87.27-20.4.08-26.79" fill="none" stroke="#eaeaea" stroke-linecap="round" stroke-miterlimit="10" stroke-width="4"/>
    <path d="M130.93,89.7V82.94c0-.19.11-.29.34-.29h3.17a3.6,3.6,0,0,1,2.38.65,2.58,2.58,0,0,1,.75,2.06v1.92a2.58,2.58,0,0,1-.74,2.06,3.56,3.56,0,0,1-2.39.65h-3.17c-.23,0-.34-.09-.34-.29m3.51-.3a4.25,4.25,0,0,0,1.44-.2,1.39,1.39,0,0,0,.77-.67,2.75,2.75,0,0,0,.24-1.25V85.36a2.75,2.75,0,0,0-.24-1.25,1.37,1.37,0,0,0-.77-.66,4,4,0,0,0-1.44-.21h-2.83V89.4Z" fill="#eaeaea"/>
    <path d="M145.47,89.7c0,.2-.11.29-.34.29h-4.94c-.23,0-.34-.09-.34-.29V82.94c0-.19.11-.29.34-.29h4.94c.23,0,.34.1.34.29s-.11.3-.34.3h-4.59V86h3c.23,0,.35.1.35.3s-.12.29-.35.29h-3V89.4h4.59c.23,0,.34.1.34.3" fill="#eaeaea"/>
    <path d="M147.65,89.53a1.82,1.82,0,0,1-.6-1.43.25.25,0,0,1,.08-.22.37.37,0,0,1,.26-.09.36.36,0,0,1,.24.08.35.35,0,0,1,.1.22,1.24,1.24,0,0,0,.4,1,2,2,0,0,0,1.26.29h1.89a2.08,2.08,0,0,0,1.32-.31A1.46,1.46,0,0,0,153,88a1.43,1.43,0,0,0-.37-1.12,2.06,2.06,0,0,0-1.31-.31h-1.8a2.53,2.53,0,0,1-1.69-.47,1.84,1.84,0,0,1-.54-1.47,1.82,1.82,0,0,1,.54-1.46,2.46,2.46,0,0,1,1.68-.47h1.86a2.54,2.54,0,0,1,1.63.43,1.75,1.75,0,0,1,.58,1.35.23.23,0,0,1-.09.21.37.37,0,0,1-.26.08.31.31,0,0,1-.23-.08.27.27,0,0,1-.11-.2,1.15,1.15,0,0,0-.36-.93,1.92,1.92,0,0,0-1.16-.27h-1.86a1.85,1.85,0,0,0-1.2.3,1.31,1.31,0,0,0-.34,1,1.34,1.34,0,0,0,.34,1,1.93,1.93,0,0,0,1.21.29h1.8a2.72,2.72,0,0,1,1.79.49,2.37,2.37,0,0,1,0,3.08,2.66,2.66,0,0,1-1.79.49h-1.89a2.79,2.79,0,0,1-1.74-.45" fill="#eaeaea"/>
    <path d="M156,89.91a.26.26,0,0,1-.09-.21V82.94a.26.26,0,0,1,.09-.21.43.43,0,0,1,.5,0,.26.26,0,0,1,.09.21V89.7a.26.26,0,0,1-.09.21.43.43,0,0,1-.5,0" fill="#eaeaea"/>
    <path d="M159.54,89.42a2.26,2.26,0,0,1-.66-1.8V85a2.26,2.26,0,0,1,.65-1.81,3.14,3.14,0,0,1,2.07-.56h1.59a3.07,3.07,0,0,1,1.81.44,1.79,1.79,0,0,1,.65,1.41c0,.19-.09.29-.34.29a.3.3,0,0,1-.34-.29,1.21,1.21,0,0,0-.45-1,2.33,2.33,0,0,0-1.33-.28H161.6a3.48,3.48,0,0,0-1.2.17,1.15,1.15,0,0,0-.64.56,2.3,2.3,0,0,0-.2,1v2.6a2.38,2.38,0,0,0,.2,1.06,1.13,1.13,0,0,0,.64.55,3.56,3.56,0,0,0,1.22.17h1.57a3.4,3.4,0,0,0,1.08-.14,1,1,0,0,0,.55-.48,2.13,2.13,0,0,0,.16-.93v-.72h-1.44c-.23,0-.35-.1-.35-.3s.12-.29.35-.29h1.79c.22,0,.34.1.34.29v1a2.08,2.08,0,0,1-.59,1.64,2.85,2.85,0,0,1-1.89.5h-1.57a3.12,3.12,0,0,1-2.08-.57" fill="#eaeaea"/>
    <path d="M174.62,83V89.7c0,.2-.12.29-.35.29a.45.45,0,0,1-.34-.17l-5.43-6V89.7c0,.2-.12.29-.34.29s-.35-.09-.35-.29V83c0-.2.12-.3.35-.3a.49.49,0,0,1,.35.17l5.42,6V83c0-.2.12-.3.34-.3s.35.1.35.3" fill="#eaeaea"/>
    <circle cx="127.81" cy="88.75" r="1.15" fill="#eaeaea"/>
    <line x1="174.91" y1="80.5" x2="131.03" y2="80.5" fill="none" stroke="#eaeaea" stroke-miterlimit="10" stroke-width="0.91"/>
    </svg>
</div>

          <Row className="my-5 align-items-center d-flex text-center">
            <p className="lead text-light"> Affiches<br/>Cartes postales<br/>Panneaux muraux</p>
          </Row>
          <Row className="my-5 align-items-center d-flex text-center">
            <p className="text-light"><svg viewBox="0 0 128 128" width="30" className="me-2">
            <path
          fill="currentcolor"
          d="M101.06,28.8A15.52,15.52,0,0,0,94.38,30c-4.68,1.69-64.55,27.11-68,28.58C23.1,60,20,61.5,20,63.7c0,1.54.92,2.41,3.45,3.31s9.27,3,13.18,4A14.25,14.25,0,0,0,47.1,69.68c2.55-1.58,32-21.28,34.1-23s3.79.49,2.06,2.22-21.93,21.34-24.6,24c-3.23,3.3-.93,6.72,1.24,8.09,2.48,1.56,20.3,13.51,23,15.43a13.67,13.67,0,0,0,7.9,2.79c2.5,0,3.8-3.28,5-7.09,1.45-4.46,8.27-48.94,9.11-57.71.25-2.65-.59-4.41-2.23-5.2A5.08,5.08,0,0,0,101.06,28.8Z"
        /></svg>    <svg id="Whatsapp"  viewBox="0 0 128 128" width="30" className="me-2">
        <g fill="currentcolor">
        <path d="M85.12,68.52c-.17-.08-6.39-3.14-7.49-3.53a4.09,4.09,0,0,0-1.45-.32,2.44,2.44,0,0,0-2.09,1.24c-.62.92-2.5,3.12-3.08,3.77-.08.09-.18.19-.24.19s-1-.39-1.31-.52c-6.67-2.9-11.73-9.86-12.43-11a.58.58,0,0,1-.1-.24,1.86,1.86,0,0,1,.36-.43c.34-.34.71-.78,1.06-1.21l.51-.59a7,7,0,0,0,1-1.61L60,54a2.9,2.9,0,0,0-.08-2.73c-.15-.3-2.77-6.63-3.05-7.3-.67-1.6-1.56-2.35-2.79-2.35l-.48,0a14.76,14.76,0,0,0-5.17,1.33c-1.49.94-4,3.93-4,9.2,0,4.74,3,9.22,4.3,10.92l.18.26C53.84,70.54,60,75.89,66.27,78.39c6,2.4,8.87,2.68,10.49,2.68h0a15.57,15.57,0,0,0,1.7-.1l.31,0c2.08-.18,6.65-2.55,7.69-5.44.82-2.28,1-4.76.49-5.66A3.94,3.94,0,0,0,85.12,68.52Z"/>
        <path d="M65.41,19.53A41.77,41.77,0,0,0,23.54,61.09a41.22,41.22,0,0,0,5.74,21l-7.19,21.22a1.09,1.09,0,0,0,.25,1.13,1.11,1.11,0,0,0,.79.33,1,1,0,0,0,.34-.05l22.12-7a42,42,0,0,0,61.7-36.62A41.77,41.77,0,0,0,65.41,19.53Zm0,74.46a33.19,33.19,0,0,1-18.25-5.44,1.09,1.09,0,0,0-.61-.18,1.26,1.26,0,0,0-.33,0L35.14,92l3.57-10.56a1.1,1.1,0,0,0-.15-1,32.45,32.45,0,0,1-6.32-19.29A33.17,33.17,0,1,1,65.41,94Z"/>
        </g>
        </svg>
     <svg id="Tel"  viewBox="0 0 128 128" width="35" className="me-2">
            <path fill="currentcolor" d="M91.38,80.73c-.2-.18-7.54-7.18-8.91-8.22a6.08,6.08,0,0,0-1.89-1.08,3.62,3.62,0,0,0-3.47.81c-1.27,1-4.86,3.27-6,3.93-.14.09-.33.19-.42.16s-1.25-1-1.6-1.3c-8-7-12.07-18.93-12.53-20.87a1.71,1.71,0,0,1,0-.39,3.27,3.27,0,0,1,.7-.44c.62-.32,1.33-.78,2-1.22l1-.61a9.59,9.59,0,0,0,2.12-1.8l.31-.33a4.21,4.21,0,0,0,1.08-3.85C63.66,45,62.77,35,62.67,34c-.23-2.54-1.14-4-2.87-4.51a6.43,6.43,0,0,0-.67-.19c-.83-.22-5.46-1-7.81-.4S44,32.63,41.68,40c-2.08,6.63.16,14.21,1.22,17.15,0,.08.07.23.14.44,3.75,12.27,10,22.45,17.67,28.68,7.36,6,11.22,7.64,13.49,8.35h0a24.57,24.57,0,0,0,2.43.61l.44.09c3,.65,10.41-.66,13.13-4.24,2.14-2.82,3.53-6.2,3.16-7.7A5.74,5.74,0,0,0,91.38,80.73Z"/>
            </svg> </p>
            <p  className=" lead text-light">+33 6 83 51 15 30</p>
          </Row>
          <Row className="d-flex justify-content-center bg-sombre">
            <Col xs={12} md={8} lg={6} className="mx-auto d-block">
              <p className="text-muted text-center">
                ©2024 noema.design
              </p>
            </Col>
          </Row>
        </Container>
    
      </footer>
    </>
  );
}

export default Footer;