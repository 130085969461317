
import "../App.scss";
import { Container, Row} from 'react-bootstrap';
import Cartfaune01 from "./../media/img/affiches/JWA-Guadeloupe-Faune1.jpg";
import Cartfaune02 from "./../media/img/affiches/JWA-Guadeloupe-Faune2.jpg";
import Cartfaune03 from "./../media/img/affiches/JWA-Guadeloupe-Faune3.jpg";
import Cartfaune04 from "./../media/img/affiches/JWA-Guadeloupe-Faune4.jpg";
import Cartfaune05 from "./../media/img/affiches/JWA-Guadeloupe-Faune5.jpg";
import Cartfaune06 from "./../media/img/affiches/JWA-Guadeloupe-Faune6.jpg";
import Cartfaune07 from "./../media/img/affiches/JWA-Guadeloupe-Faune7.jpg";

function StickyCardsInsideSection() {
  var lastScrollTop = 0;

  window.addEventListener('scroll', function() {
    var currentScroll = window.pageYOffset || document.documentElement.scrollTop;
    var targetElement = document.getElementById('tortue');
  
    if (currentScroll > lastScrollTop) {
      // Scrolling down
      targetElement.classList.add('animate');
    } else {
      // Scrolling up
      targetElement.classList.remove('animate')
    }
    lastScrollTop = currentScroll <= 0 ? 0 : currentScroll; // For mobile or negative scrolling
  });
  return (
    <>
    <svg className="mt-n5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1366 113.08"><g id="Calque_2" data-name="Calque 2"><g id="Calque_1-2" data-name="Calque 1"><path d="M1366,17.08s-65,10-146,7c-65.43-2.43-68.37-24.09-164-21-124,4-125,14-227,16C723,21.16,706.84-1.48,621,.08c-55,1-136,21-193,20-32.47-.57-156-18-267-17-83,.75-161,16-161,16v94H1366Z" fill="#eaeaea"/></g></g></svg>
    <Container fluid className="mb-5 ">
     <Row className="mx-auto text-center">
            <svg  className="mt-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 143.53 85" height="85">
      <path  d="M.39,10.7C9.89-.78,19.32.36,21.39.7c12,2,18,14,20,24,2.23,11.18,6.27,21.24,13.5,28.5,10.86,10.92,27,12.32,28.5,12.5,17.69,2.07,37-3,39.5-2.5,6,1.19,10.41,5.74,12,7,2,1.57,9.23,7.37,8,10-.95,2-6.55,1.39-10,1a23.8,23.8,0,0,1-8-2c-5.26-2.45-5.21-5.19-9-7-4.29-2-6.39.49-15,1-10.07.59-17.63-2.25-18-1C82.57,73.26,88,77.82,88,78.82c0,2.24-5.64,2.42-8.32,2.56s-9.27.19-11.94-4.26c-1.5-2.5-3.38-6.42-4.38-7.42-3.81-3.81-10.64-4.24-16.5-7.5-4.5-2.5-8.49-6.49-19-16s-16.76-19.41-26-31m44.59.52C44.79,17.53,50.31,35,50.8,35.93A47.07,47.07,0,0,0,62.36,51c6.28,5.31,12.32,7.42,16.81,9a66.07,66.07,0,0,0,23.91,3.23c10.82-.28,24.12-2.75,27.91-4.31A3.48,3.48,0,0,0,132.85,56c-.14-1.71-2.61-2.48-4.32-3.55-4.59-2.87-4.22-8.17-6.94-14.65-2.42-5.75-5.93-9.22-9.72-13A47.83,47.83,0,0,0,92.28,12.64C88,11.28,79.55,8.62,71,11.1S58.15,14.81,57.89,15c-1.14.64-3.25,1.95-5.24,1.23-1.72-.61-2.27-2.35-3.71-2.31C47.69,13.91,46.86,15.31,46.48,15.72Zm-12,37a83.24,83.24,0,0,1-11.83,16C21.21,70.21,19,73.41,19,73.9c.09,3.52,4.84,6.47,8.58,7.42s9.77.61,13.28-3.71c1.41-1.74.78-3,2.53-7.22a36.08,36.08,0,0,1,3.32-6.24"
      fill="none" stroke="#444" stroke-width="0.5"/>
       <path  id="tortue" d="M.39,10.7C9.89-.78,19.32.36,21.39.7c12,2,18,14,20,24,2.23,11.18,6.27,21.24,13.5,28.5,10.86,10.92,27,12.32,28.5,12.5,17.69,2.07,37-3,39.5-2.5,6,1.19,10.41,5.74,12,7,2,1.57,9.23,7.37,8,10-.95,2-6.55,1.39-10,1a23.8,23.8,0,0,1-8-2c-5.26-2.45-5.21-5.19-9-7-4.29-2-6.39.49-15,1-10.07.59-17.63-2.25-18-1C82.57,73.26,88,77.82,88,78.82c0,2.24-5.64,2.42-8.32,2.56s-9.27.19-11.94-4.26c-1.5-2.5-3.38-6.42-4.38-7.42-3.81-3.81-10.64-4.24-16.5-7.5-4.5-2.5-8.49-6.49-19-16s-16.76-19.41-26-31m44.59.52C44.79,17.53,50.31,35,50.8,35.93A47.07,47.07,0,0,0,62.36,51c6.28,5.31,12.32,7.42,16.81,9a66.07,66.07,0,0,0,23.91,3.23c10.82-.28,24.12-2.75,27.91-4.31A3.48,3.48,0,0,0,132.85,56c-.14-1.71-2.61-2.48-4.32-3.55-4.59-2.87-4.22-8.17-6.94-14.65-2.42-5.75-5.93-9.22-9.72-13A47.83,47.83,0,0,0,92.28,12.64C88,11.28,79.55,8.62,71,11.1S58.15,14.81,57.89,15c-1.14.64-3.25,1.95-5.24,1.23-1.72-.61-2.27-2.35-3.71-2.31C47.69,13.91,46.86,15.31,46.48,15.72Zm-12,37a83.24,83.24,0,0,1-11.83,16C21.21,70.21,19,73.41,19,73.9c.09,3.52,4.84,6.47,8.58,7.42s9.77.61,13.28-3.71c1.41-1.74.78-3,2.53-7.22a36.08,36.08,0,0,1,3.32-6.24"
      fill="none" stroke="#000" stroke-width="2"/>
      </svg>
      <p className="lead mt-3">Faune</p>
        </Row>
       <div className="galerie flex-wrap d-flex justify-content-center">
    <div className="one d-inline-flex sticky-card my-5 mx-xl-5 mx-lg-2">     
        <img className="border border-dark m-0 mx-auto shadow" src={Cartfaune01} height="450px" alt="Guadeloupe carte postale Tortue"/>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 87.5 75" height="75" id="fauneCard">
        <rect x="30" y="0" fill="#eaeaea" width="60" height="55"></rect>
        <text transform="translate(45 25)">01</text>
    <line x1="87.5" y1="55" y2="55" stroke="#000"/></svg>  
    </div>
    
    <div className="one d-inline-flex sticky-card my-5 mx-xl-5 mx-lg-2">
   <img className="border border-dark m-0 mx-auto  shadow" src={Cartfaune02} height="450px" alt="Guadeloupe carte postale Iguane"/>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 87.5 75" height="75" id="fauneCard">
        <rect x="30" y="0" fill="#eaeaea" width="60" height="55"></rect>
     <text transform="translate(45 25)">02</text>
      
      <line x1="87.5" y1="55" y2="55" stroke="#000"/></svg> 
    </div>
    <div className="two d-inline-flex sticky-card my-5 mx-xl-5 mx-lg-2">
    <img className="border border-dark m-0 mx-auto  shadow" src={Cartfaune03} height="450px" alt="Guadeloupe carte postale Colibri"/>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 87.5 75" height="75" id="fauneCard">
    <rect x="30" y="0" fill="#eaeaea" width="60" height="55"></rect>
    <rect x="30" y="0" fill="#eaeaea" width="60" height="55"></rect>
    <text transform="translate(45 25)">03</text>
    <line x1="87.5" y1="55" y2="55" stroke="#000"/></svg> 
    </div>
    <div className="two d-inline-flex sticky-card my-5 mx-xl-5 mx-lg-2">
    <img className="border border-dark m-0 mx-auto  shadow" src={Cartfaune04} height="450px" alt="Guadeloupe carte postale Racoon"/>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 87.5 75" height="75" id="fauneCard">
    <rect x="30" y="0" fill="#eaeaea" width="60" height="55"></rect>
    <text transform="translate(45 25)">04</text>
    <line x1="87.5" y1="55" y2="55" stroke="#000"/></svg> 
    </div>
    <div className="three d-inline-flex sticky-card my-5 mx-xl-5 mx-lg-2">
     <img className="border border-dark m-0 mx-auto  shadow" src={Cartfaune05} height="450px" alt="Guadeloupe carte postale Frégate"/> <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 87.5 75" height="75" id="fauneCard">
     <rect x="30" y="0" fill="#eaeaea" width="60" height="55"></rect>
     <text transform="translate(45 25)">05</text>
    <line x1="87.5" y1="55" y2="55" stroke="#000"/></svg> 
    </div>
    
    <div className="three d-inline-flex sticky-card my-5 mx-xl-5 mx-lg-2">
     <img className="border border-dark m-0 mx-auto  shadow" src={Cartfaune06} height="450px" alt="Guadeloupe carte postale Baleine"/> <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 87.5 75" height="75" id="fauneCard">
     <rect x="30" y="0" fill="#eaeaea" width="60" height="55"></rect>
     <text transform="translate(45 25)">06</text>
    <line x1="87.5" y1="55" y2="55" stroke="#000"/></svg> 
    </div>
    <div className="four d-inline-flex sticky-card my-5 mx-xl-5 mx-lg-2">
     <img className="border border-dark m-0 mx-auto  shadow" src={Cartfaune07} height="450px" alt="Guadeloupe carte postale Baleine"/> <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 87.5 75" height="75" id="fauneCard">
     <rect x="30" y="0" fill="#eaeaea" width="60" height="55"></rect>
     <text transform="translate(45 25)">07</text>
    <line x1="87.5" y1="55" y2="55" stroke="#000"/></svg> 
    </div>
 
  </div>
    </Container>
  
  </>
  );
};

export default StickyCardsInsideSection;