
import "../App.scss";
import { Container, Row} from 'react-bootstrap';
import Cart01 from "./../media/img/affiches/Jwa-Guadeloupe-01.jpg";
import Cart02 from "./../media/img/affiches/Jwa-Guadeloupe-02.jpg";
import Cart03 from "./../media/img/affiches/Jwa-Guadeloupe-03.jpg";
import Cart04 from "./../media/img/affiches/Jwa-Guadeloupe-04.jpg";
import Cart05 from "./../media/img/affiches/Jwa-Guadeloupe-05.jpg";
import Cart06 from "./../media/img/affiches/Jwa-Guadeloupe-06.jpg";
import Cart07 from "./../media/img/affiches/Jwa-Guadeloupe-07.jpg";
import Cart08 from "./../media/img/affiches/Jwa-Guadeloupe-08.jpg";
import Cart09 from "./../media/img/affiches/Jwa-Guadeloupe-09.jpg";
import Cart10 from "./../media/img/affiches/Jwa-Guadeloupe-10.jpg";
import Cart11 from "./../media/img/affiches/Jwa-Guadeloupe-11.jpg";
import Cart12 from "./../media/img/affiches/Jwa-Guadeloupe-12.jpg";
import Cart13 from "./../media/img/affiches/Jwa-Guadeloupe-13.jpg";
import Cart14 from "./../media/img/affiches/Jwa-Guadeloupe-14.jpg";
import Dollar from "./../media/Dollar.svg";
import { useEffect } from "react"
// importing aos
import AOS from "aos";
import "aos/dist/aos.css";

function StickyCardsInsideSection() {
    useEffect(() => {
        AOS.init();
      }, []);

      var lastScrollTop = 0;

      window.addEventListener('scroll', function() {
        var currentScroll = window.pageYOffset || document.documentElement.scrollTop;
        var targetElement = document.getElementById('barque');
      
        if (currentScroll > lastScrollTop) {
          // Scrolling down
          targetElement.classList.add('animate');
        } else {
          // Scrolling up
          targetElement.classList.remove('animate')
        }
        lastScrollTop = currentScroll <= 0 ? 0 : currentScroll; // For mobile or negative scrolling
      });
   
  return (
    <>
   <svg className="mt-n4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1366 113.08" width="100%"><path d="M1366,17.08s-65,10-146,7c-65.43-2.43-68.37-24.09-164-21-124,4-125,14-227,16C723,21.16,706.84-1.48,621,.08c-55,1-136,21-193,20-32.47-.57-156-18-267-17-83,.75-161,16-161,16v94H1366Z" fill="#eaeaea"/></svg>
       
      
    <Container fluid className="mb-5 position-relative">
        <Row className="mx-auto text-center align-items-center">
        <h1>Affiches & cartes</h1>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192.75 1" height="1"><line y1="0.5" x2="192.75" y2="0.5" fill="none" stroke="#555" stroke-miterlimit="10"/></svg>
       
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 186.27 90.38" height="75" className="mt-5">
        <path id="barque" d="M10,40.74C40,41.28,141.83,12.41,149.5.5l33,2s-11.69,23.13-34.09,34.32c-30.81,19.9-96,20.58-131.71,6.71m155.8-27L152,13C139.67,26.22,62.68,48.39,49,50.44M88.5,40.5l28.6,8M105.34,35.16l28,8M130.5,25.5l25,7M142.25,19.17l23,5M25.08,77.52c-14-10.4-14.86-39.8-15.58-42-.87-2.67-8.37-2.54-9,0,3.29,92,114.76,48.5,166,6a101.18,101.18,0,0,0,19-26L185.77.3" fill="none" stroke="#000" stroke-width="2" stroke-linejoin="round"/>
        </svg>
        <p className="mt-3 lead"> Paysages & scènes</p>
       
        </Row>

        <Row className="mx-auto my-5 flex-wrap flex-row d-flex justify-content-center">
 <div id="svgCard" className="text-center" data-aos="fade-up"
            data-aos-duration="2000">
    <svg className="mx-auto" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 410 498.95" height="500">
        <path className="shapePath shap1"></path>
<image transform="translate(71 17) scale(0.50)" id="image" width="597" height="843" href={Cart01} alt="Guadeloupe carte affiche Saint-François"/>
<text  transform="translate(0.03 38.19)">01</text>
<line x1="87.5" y1="54.58" y2="54.58" fill="none" stroke="#000"/>
<rect x="69.54" y="17" width="301" height="424" fill="none"  stroke="#000"/>
</svg> </div> 
<div id="svgCard" className="text-center" data-aos="fade-up"
            data-aos-duration="2000">
     <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 410 498.95" height="500">
        <path className="shapePath shap1"></path>
<image transform="translate(71 17) scale(0.50)" id="image" width="597" height="843" href={Cart02} alt="Guadeloupe carte affiche Saint-François"/>
<text  transform="translate(0.03 38.19)">02</text>
<line x1="87.5" y1="54.58" y2="54.58" fill="none" stroke="#000"/>
<rect x="69.54" y="17" width="301" height="424" fill="none"  stroke="#000"/>
</svg></div>
<div id="svgCard" className="text-center" data-aos="fade-up"
            data-aos-duration="2000">
    <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 410 498.95" height="500">
        <path className="shapePath shap1"></path>
<image transform="translate(71 17) scale(0.50)" id="image" width="597" height="843" href={Cart03} alt="Guadeloupe carte affiche Saint-François"/>
<text  transform="translate(0.03 38.19)">03</text>
<line x1="87.5" y1="54.58" y2="54.58" fill="none" stroke="#000"/>
<rect x="69.54" y="17" width="301" height="424" fill="none"  stroke="#000"/>
</svg></div>
<div id="svgCard" className="text-center" data-aos="fade-up"
            data-aos-duration="2000">
    <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 410 498.95" height="500">
        <path className="shapePath shap1"></path>
<image transform="translate(71 17) scale(0.50)" id="image" width="597" height="843" href={Cart04} alt="Guadeloupe carte affiche Saint-François"/>
<text  transform="translate(0.03 38.19)">04</text>
<line x1="87.5" y1="54.58" y2="54.58" fill="none" stroke="#000"/>
<rect x="69.54" y="17" width="301" height="424" fill="none"  stroke="#000"/>
</svg></div>
<div id="svgCard" className="text-center" data-aos="fade-up"
            data-aos-duration="2000">
    <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 410 498.95" height="500">
        <path className="shapePath shap1"></path>
<image transform="translate(71 17) scale(0.50)" id="image" width="597" height="843" href={Cart05} alt="Guadeloupe carte affiche Saint-François"/>
<text  transform="translate(0.03 38.19)">05</text>
<line x1="87.5" y1="54.58" y2="54.58" fill="none" stroke="#000"/>
<rect x="69.54" y="17" width="301" height="424" fill="none"  stroke="#000"/>
</svg></div>
<div id="svgCard" className="text-center" data-aos="fade-up"
            data-aos-duration="2000">
    <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 410 498.95" height="500">
        <path className="shapePath shap1"></path>
<image transform="translate(71 17) scale(0.50)" id="image" width="597" height="843" href={Cart06} alt="Guadeloupe carte affiche Saint-François"/>
<text  transform="translate(0.03 38.19)">06</text>
<line x1="87.5" y1="54.58" y2="54.58" fill="none" stroke="#000"/>
<rect x="69.54" y="17" width="301" height="424" fill="none"  stroke="#000"/>
</svg></div>
<div id="svgCard" className="text-center" data-aos="fade-up"
            data-aos-duration="2000">
    <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 410 498.95" height="500">
        <path className="shapePath shap1"></path>
<image transform="translate(71 17) scale(0.50)" id="image" width="597" height="843" href={Cart07} alt="Guadeloupe carte affiche Le Moule"/>
<text  transform="translate(0.03 38.19)">07</text>
<line x1="87.5" y1="54.58" y2="54.58" fill="none" stroke="#000"/>
<rect x="69.54" y="17" width="301" height="424" fill="none"  stroke="#000"/>
</svg></div>
<div id="svgCard" className="text-center" data-aos="fade-up"
            data-aos-duration="2000">
    <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 410 498.95" height="500">
        <path className="shapePath shap1"></path>
<image transform="translate(71 17) scale(0.50)" id="image" width="597" height="843" href={Cart08} alt="Guadeloupe carte affiche Petit-Canal"/>
<text  transform="translate(0.03 38.19)">08</text>
<line x1="87.5" y1="54.58" y2="54.58" fill="none" stroke="#000"/>
<rect x="69.54" y="17" width="301" height="424" fill="none"  stroke="#000"/>
</svg></div>
<div id="svgCard" className="text-center" data-aos="fade-up"
            data-aos-duration="2000">
    <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 410 498.95" height="500">
        <path className="shapePath shap1"></path>
<image transform="translate(71 17) scale(0.50)" id="image" width="597" height="843" href={Cart09} alt="Guadeloupe carte affiche Port-Louis"/>
<text  transform="translate(0.03 38.19)">09</text>
<line x1="87.5" y1="54.58" y2="54.58" fill="none" stroke="#000"/>
<rect x="69.54" y="17" width="301" height="424" fill="none"  stroke="#000"/>
</svg></div>
<div id="svgCard" className="text-center" data-aos="fade-up"
            data-aos-duration="2000">
<svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 410 498.95" height="500">
        <path className="shapePath shap1"></path>
<image transform="translate(71 17) scale(0.50)" id="image" width="597" height="843" href={Cart10} alt="Guadeloupe carte affiche Vieux-Fort"/>
<text  transform="translate(0.03 38.19)">10</text>
<line x1="87.5" y1="54.58" y2="54.58" fill="none" stroke="#000"/>
<rect x="69.54" y="17" width="301" height="424" fill="none"  stroke="#000"/>
</svg></div> 
<div id="svgCard" className="text-center" data-aos="fade-up"
            data-aos-duration="2000">
<svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 410 498.95" height="500">
        <path className="shapePath shap1"></path>
<image transform="translate(71 17) scale(0.50)" id="image" width="597" height="843" href={Cart11} alt="Guadeloupe carte affiche Saint-François"/>
<text  transform="translate(0.03 38.19)">11</text>
<line x1="87.5" y1="54.58" y2="54.58" fill="none" stroke="#000"/>
<rect x="69.54" y="17" width="301" height="424" fill="none"  stroke="#000"/>
</svg></div>
<div id="svgCard" className="text-center" data-aos="fade-up"
            data-aos-duration="2000">
<svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 410 498.95" height="500">
        <path className="shapePath shap1"></path>
<image transform="translate(71 17) scale(0.50)" id="image" width="597" height="843" href={Cart12} alt="Guadeloupe carte affiche Saint-François"/>
<text  transform="translate(0.03 38.19)">12</text>
<line x1="87.5" y1="54.58" y2="54.58" fill="none" stroke="#000"/>
<rect x="69.54" y="17" width="301" height="424" fill="none"  stroke="#000"/>
</svg></div>
<div id="svgCard" className="text-center" data-aos="fade-up"
            data-aos-duration="2000">
<svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 410 498.95" height="500">
        <path className="shapePath shap1"></path>
<image transform="translate(71 17) scale(0.50)" id="image" width="597" height="843" href={Cart13} alt="Guadeloupe carte affiche Saint-François"/>
<text  transform="translate(0.03 38.19)">12</text>
<line x1="87.5" y1="54.58" y2="54.58" fill="none" stroke="#000"/>
<rect x="69.54" y="17" width="301" height="424" fill="none"  stroke="#000"/>
</svg></div>
<div id="svgCard" className="text-center" data-aos="fade-up"
            data-aos-duration="2000">
<svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 410 498.95" height="500">
        <path className="shapePath shap1"></path>
<image transform="translate(71 17) scale(0.50)" id="image" width="597" height="843" href={Cart14} alt="Guadeloupe carte affiche Saint-François"/>
<text  transform="translate(0.03 38.19)">12</text>
<line x1="87.5" y1="54.58" y2="54.58" fill="none" stroke="#000"/>
<rect x="69.54" y="17" width="301" height="424" fill="none"  stroke="#000"/>
</svg></div>
  </Row> <div className="d-none d-md-block dollar z-n1 position-absolute"><img src={Dollar} height={250} alt=""/></div> 
    </Container>

  </>
  );
};

export default StickyCardsInsideSection;