function Draw() {
   
    return (
           <>
           <div className="mb-n3  mb-lg-n4">
           <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1366 113.08"  width="100%">
<path d="M.11,96s65-10,146-7c65.43,2.42,68.37,24.08,164,21,124-4,125-14,227-16,106-2.08,122.16,20.56,208,19,55-1,136-21,193-20,32.47.57,156,18,267,17,83.05-.75,161-16,161-16V0H.11Z" fill="#eaeaea"/>

<path id="animWave1"></path>
<path id="animWave2"></path>
</svg>
</div>
</>
        );
    }
    
export default Draw;